import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Asegúrate de que esta ruta sea correcta para tu configuración de Firebase
import { toast } from 'react-toastify';
import datos from './datos.json'; // Importar el JSON
import logo from '../assets/logo.png'; // Importar el logo
import confetiGif from '../assets/confeti.gif'; // Importar el GIF de confeti


const Ganadores = () => {
  const [numeroSorteo, setNumeroSorteo] = useState('');
  const [ganadores, setGanadores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);


  // Función para enmascarar el teléfono
  const enmascararTelefono = (telefono) => {
    if (telefono.length > 4) {
      const inicio = telefono.slice(0, 2); // Los primeros 2 dígitos (código del país)
      const fin = telefono.slice(-4); // Los últimos 4 dígitos del teléfono
      return `${inicio}******${fin}`;
    }
    return telefono;
  };

  const generarNombreAleatorio = () => {
    const nombres = ["María Fernanda", "Ana Sofía", "Lucía", "Patricia", "Gabriela", "Daniela", "Valeria", "Mariana", "Alejandra", "Camila", "Paola", "Sara", "Laura", "Ana María", "Carla", "Beatriz", "Isabel", "Elena", "Diana", "Natalia", "Claudia Patricia", "Silvia", "Renata", "Irene", "Mónica", "Fátima", "Rosa María", "Teresa", "Clara", "Inés", "Lorena", "Julia", "Rocío", "Florencia", "Esther", "Lidia", "Nora", "Ariadna", "Eva", "Ángela", "Noemí", "Cecilia", "Leticia", "Verónica", "Marta", "Viviana", "Jimena", "Alejandrina", "Amelia", "María José", "Sofía Isabel", "Lucía Fernanda", "Ana Paula", "María Guadalupe", "Claudia María", "Andrea Sofía", "María Elena", "María Victoria", "Carla Sofía", "Gabriela Isabel", "Mónica Alejandra", "Paola Fernanda", "Laura Beatriz", "Ana Lucía", "Elena Patricia", "Diana Carolina", "Patricia María", "María Cristina", "Sara Isabel", "Natalia Andrea", "Valeria Sofía", "Ana Gabriela", "María Teresa", "Florencia Isabel", "Lucía Carolina", "Isabel Sofía", "Clara Beatriz", "Gabriela Carolina", "María Camila", "Lucía Patricia", "Laura Fernanda", "Ana Claudia", "Sofía Andrea", "Renata Isabel", "Ana Isabel", "Camila Sofía", "Alejandra Carolina", "Elena Beatriz", "Silvia Patricia", "María Fernanda", "Ana Laura", "María Valentina", "Sara Fernanda", "Diana Isabel", "Carla Alejandra", "Mónica Patricia", "Jimena Sofía", "Viviana Carolina"]    ;
    const apellidos = ["García", "Martínez", "Rodríguez", "López", "Hernández", "González", "Pérez", "Sánchez", "Ramírez", "Cruz", "Flores", "Ortiz", "Vargas", "Gómez", "Díaz", "Álvarez", "Mendoza", "Morales", "Castillo", "Ramos", "Reyes", "Jiménez", "Domínguez", "Vázquez", "Guerrero", "Chávez", "Silva", "Castro", "Romero", "Soto", "Gutiérrez", "Mejía", "Pineda", "Ibarra", "Ríos", "Cárdenas", "Fernández", "Salazar", "Rangel", "Esquivel", "Aguilar", "Zamora", "Carrillo", "Montes", "Benítez", "Luna", "Orozco", "Mora", "Miranda", "Escobar", "Acosta", "Muñoz", "Campos", "Torres García", "Martínez López", "Rodríguez Hernández", "López Pérez", "González Sánchez", "Ramírez Cruz", "Vargas Flores", "Gómez Ortiz", "Díaz Vargas", "Álvarez Ramírez", "Morales González", "Castillo Pérez", "Ramos López", "Reyes Sánchez", "Jiménez Ramírez", "Domínguez Cruz", "Vázquez Flores", "Guerrero Ortiz", "Chávez Vargas", "Silva Gómez", "Castro Díaz", "Romero Álvarez", "Soto Mendoza", "Gutiérrez Morales", "Mejía Castillo", "Pineda Ramos", "Ibarra Reyes", "Ríos Jiménez", "Cárdenas Domínguez", "Fernández Vázquez", "Salazar Guerrero", "Rangel Chávez", "Esquivel Silva", "Aguilar Castro", "Zamora Romero", "Carrillo Soto", "Montes Gutiérrez", "Benítez Mejía", "Luna Pineda", "Orozco Ibarra", "Mora Ríos", "Miranda Cárdenas", "Escobar Fernández", "Acosta Salazar", "Muñoz Rangel", "Campos Esquivel", "Torres Aguilar"]    ;
    return `${nombres[Math.floor(Math.random() * nombres.length)]} ${apellidos[Math.floor(Math.random() * apellidos.length)]}`;
  };

  const handleBuscarGanador = async () => {
    if (!numeroSorteo) {
      toast.warn('Por favor, ingresa un número de sorteo para buscar.');
      return;

    }
    
    setLoading(true);
    setGanadores([]); // Resetear ganadores antes de la búsqueda
    setShowConfetti(true); // Mostrar el confeti cuando se encuentra un ganador


    try {
      const clientesRef = collection(db, 'clientes');
      const q = query(clientesRef, where('boletosRegistrados', 'array-contains', numeroSorteo));
      const querySnapshot = await getDocs(q);

      const ganadoresEncontrados = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        ganadoresEncontrados.push({
          nombre: data.nombre || 'No disponible',
          telefono: doc.id, // El ID del documento es el número de teléfono
          boletos: data.boletosRegistrados ? data.boletosRegistrados.join(', ') : 'Ninguno',
        });
      });

      if (ganadoresEncontrados.length > 0) {
        setGanadores(ganadoresEncontrados);
        toast.success('Ganador(es) encontrado(s)');
      } else {
        // Si no se encuentra el ganador en la colección de Firebase, buscar en el archivo JSON
        if (datos[numeroSorteo]) {
          const nombreJson = datos[numeroSorteo].nombre;
          const telefonoJson = enmascararTelefono(datos[numeroSorteo].telefono); // Enmascarar el teléfono aquí
          setGanadores([
            {
              nombre: nombreJson,
              telefono: telefonoJson,
              boletos: numeroSorteo, // Aquí asignamos el número del sorteo ingresado
            },
          ]);
          toast.success(`Numero encontrado: ${nombreJson}`);
        } else {
          // Si el número no existe en Firebase ni en JSON, asignar un nuevo nombre y mostrarlo
          const nuevoNombre = generarNombreAleatorio();
          const nuevoTelefono = `52${Math.floor(Math.random() * 10000000000)}`;
          setGanadores([
            {
              nombre: nuevoNombre,
              telefono: enmascararTelefono(nuevoTelefono), // Enmascarar el nuevo teléfono aquí
              boletos: numeroSorteo, // Aquí asignamos el número del sorteo ingresado
            },
          ]);
          toast.success(`Numero encontrado`);
        }
      }
    } catch (error) {
      console.error('Error al buscar el numero:', error);
      toast.error('Hubo un error al buscar el numero.');
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      {/* Agregar el logo en la parte superior */}
      <img src={logo} alt="Logo" className="w-32 mb-6" />

      <h1 className="text-3xl font-bold mb-8">Buscar Numero</h1>
      
      <div className="w-full max-w-lg">
      <input
  type="text"
  placeholder="Ingresa el número del sorteo"
  value={numeroSorteo}
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, ''); // Eliminar todo lo que no sea un número
    if (value.length <= 5) { // Limitar a 5 caracteres
      setNumeroSorteo(value);
    }
  }}
  maxLength="5" // Limitar el número de caracteres a 5
  className="w-full p-3 mb-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
/>


        <button
          onClick={handleBuscarGanador}
          className={`w-full p-3 bg-blue-600 text-white rounded-md font-bold text-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? 'Buscando...' : 'Buscar Numero'}
        </button>
        {showConfetti && (
  <div className="absolute inset-0 flex justify-center items-center">
    <img src={confetiGif} alt="Confeti" className="w-full h-full object-cover" />
  </div>
)}

        {/* Mostrar resultado de los ganadores */}
        {ganadores.length > 0 && (
          <div className="mt-8 p-6 bg-white shadow-lg rounded-lg text-center">
            <h2 className="text-xl font-bold mb-4">Ganador Encontrado!</h2>
            {ganadores.map((ganador, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-lg mb-2"><strong>Nombre:</strong> {ganador.nombre}</h3>
                <p className="text-lg mb-2"><strong>Teléfono:</strong> {ganador.telefono}</p>
                <p className="text-lg"><strong>Numero:</strong> {ganador.boletos}</p>
              </div>
            ))}
          </div>
        )}

        {/* Mostrar mensaje si no hay resultados */}
        {ganadores.length === 0 && !loading && numeroSorteo && (
          <p className="mt-4 text-lg text-gray-700">Introduce un número de sorteo para buscar el ganador.</p>
        )}
      </div>
    </div>
  );
};

export default Ganadores;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import SorteosPanel from './components/SorteosPanel';
import Sorteo from './components/Sorteo';
import ThankYouPage from './components/ThankYouPage';
import Ganadores from './components/Ganadores.js';  // Importamos el nuevo componente

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Agregar un estado de carga
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Establecer el usuario actual (null si no está autenticado)
      setLoading(false);    // Finalizar el estado de carga cuando se haya verificado la autenticación
    });

    return () => unsubscribe();
  }, [auth]);

  // Mientras esté cargando la verificación de la autenticación, mostrar un indicador de carga
  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route 
          path="/plataforma" 
          element={user ? <Navigate to="/panel" /> : <Login />} 
        />
        <Route path="/register" element={<Register />} />
        <Route 
          path="/panel" 
          element={user ? <SorteosPanel /> : <Navigate to="/plataforma" />} 
        />
        <Route path="/sorteos/:id" element={<Sorteo />} />
        <Route path="/thankyoupage" element={<ThankYouPage />} />
        {/* Verificar si hay un usuario activo para mostrar Ganadores o redirigir */}
        <Route 
          path="/ganadores" 
          element={user ? <Ganadores /> : <Navigate to="/plataforma" />} 
        />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;

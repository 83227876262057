import React, { useState } from 'react';
import { collection, doc, setDoc, updateDoc, increment, Timestamp, getDoc } from 'firebase/firestore';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { db, storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


const CrearSorteoModal = ({ isOpen, onClose, onCreate }) => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [cantidad, setCantidad] = useState(10000);
  const [costo, setCosto] = useState(0);
  const [limitNumbers, setLimitNumbers] = useState(false);
  const [maxNumbersPerPerson, setMaxNumbersPerPerson] = useState(0);
  const [enablePackages, setEnablePackages] = useState(false);
  const [packageSizes, setPackageSizes] = useState([]);
  const [packageDiscounts, setPackageDiscounts] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [error, setError] = useState('');
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [currentStep, setCurrentStep] = useState(1);

  const [resetHour, setResetHour] = useState('00:00:00');


  // Nuevo estado para manejar la opción de mostrar imagen de portada y temporizador
  const [showMediaAndTimer, setShowMediaAndTimer] = useState(true);

  // Nuevo estado para redirigir el formulario
  const [redirectToExternalPage, setRedirectToExternalPage] = useState(false);
  const [externalPageUrl, setExternalPageUrl] = useState('');

  // Estado para manejo de imagen, gif y video
  const [mediaType, setMediaType] = useState('image');
  const [mediaFile, setMediaFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [videoPoster, setVideoPoster] = useState(null); 
  const [posterUploadProgress, setPosterUploadProgress] = useState(0);
  const [isPosterUploadComplete, setIsPosterUploadComplete] = useState(false);

  const [showTitle, setShowTitle] = useState(true);
  const [showDescription, setShowDescription] = useState(true);

  // Estado para manejar días y horarios de inactividad
  const [inactiveDays, setInactiveDays] = useState([]);
  const [inactiveHours, setInactiveHours] = useState({ start: '00:00', end: '23:59' });

  const calculateDateDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleResetHourChange = (value) => {
    setResetHour(value);  // Permitir que el usuario escriba libremente
  
    // Solo validamos cuando el usuario ingresa un formato completo de "HH:MM:SS"
    if (value.length === 8) {
      const timePattern = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;  // Patrón de validación para HH:MM:SS
  
      if (!timePattern.test(value)) {
        setError("Formato de hora inválido. Usa el formato HH:MM:SS");  // Mostrar error si el formato es incorrecto
      } else {
        setError("");  // Limpiar el error si el formato es correcto
      }
    } else {
      setError("");  // Limpiar error si el usuario aún no ha ingresado todo el valor
    }
  };
  
  

  const generarNumerosSorteo = async (sorteoId, cantidadInicial, cantidadMaxima, limitePorPersona, paquetes) => {
    const disponiblesRef = doc(db, `sorteos/${sorteoId}/boletos`, 'disponibles');
    const numerosDisponibles = [];

    // Asegurarse de que cantidadInicial y cantidadMaxima sean números válidos
    cantidadInicial = parseInt(cantidadInicial, 10);
    cantidadMaxima = parseInt(cantidadMaxima, 10);

    if (isNaN(cantidadInicial) || isNaN(cantidadMaxima)) {
      throw new Error("La cantidad inicial o máxima no es un número válido.");
    }

    const limit = Math.min(cantidadInicial, cantidadMaxima);

    for (let i = 1; i <= limit; i++) {
      const numero = i.toString().padStart(5, '0');
      numerosDisponibles.push(numero);
    }

    await setDoc(disponiblesRef, {
      numeros: numerosDisponibles
    });

    await updateDoc(doc(db, 'sorteos', sorteoId), {
      cantidadActual: limit,
      cantidadMaxima: cantidadMaxima,
      limitePorPersona: limitePorPersona || null,
      paquetes: paquetes || []
    });
  };

  const uploadFileToStorage = async (file, path, setProgress, setComplete) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);

          if (progress === 100) {
            setComplete(true);
          }
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleCrearSorteo = async () => {
    const fechaInicio = dateRange[0].startDate;
    const fechaFin = dateRange[0].endDate;
    const dateDifference = calculateDateDifference(fechaInicio, fechaFin);

    if (showMediaAndTimer && dateDifference < 5) {
      setError('La duración del sorteo debe ser de al menos 5 días.');
      return;
    }

    setIsSubmitting(true);

    try {
      const counterDocRef = doc(db, 'counters', 'sorteoCounter');
      const counterDoc = await getDoc(counterDocRef);

      let currentCount;
      if (counterDoc.exists()) {
        currentCount = counterDoc.data().current;
      } else {
        currentCount = 100;
        await setDoc(counterDocRef, { current: currentCount });
      }

      const newSorteoId = `edicion-${currentCount}`;
      let mediaLink = '';

      if (showMediaAndTimer && mediaType === 'video' && mediaFile) {
        mediaLink = await uploadFileToStorage(mediaFile, `sorteos/${newSorteoId}/${mediaFile.name}`, setUploadProgress, setIsUploadComplete);
      }

      let posterLink = '';
      if (showMediaAndTimer && videoPoster) {
        posterLink = await uploadFileToStorage(videoPoster, `sorteos/${newSorteoId}/poster_${videoPoster.name}`, setPosterUploadProgress, setIsPosterUploadComplete);
      }

      const parsedCantidad = parseInt(cantidad, 10);
      const parsedCosto = parseInt(costo, 10);

      if (isNaN(parsedCantidad) || parsedCantidad <= 0 || (!isFree && (isNaN(parsedCosto) || parsedCosto <= 0))) {
        throw new Error("Cantidad o costo no válidos.");
      }

      await setDoc(doc(db, 'sorteos', newSorteoId), {
        nombre,
        descripcion,
        costo: isFree ? 0 : parsedCosto,
        fechaInicio: showMediaAndTimer ? Timestamp.fromDate(fechaInicio) : null,
        fechaFin: showMediaAndTimer ? Timestamp.fromDate(fechaFin) : null,
        cantidadActual: 0,
        cantidadMaxima: parsedCantidad,
        limitePorPersona: isFree ? 1 : (limitNumbers ? maxNumbersPerPerson : null),
        paquetes: enablePackages ? packageSizes.map((size, index) => ({
          size: size,
          discount: packageDiscounts[index] || 0
        })) : [],
        esGratuito: isFree,
        mediaLink: showMediaAndTimer ? mediaLink : null,
        posterLink: showMediaAndTimer ? posterLink : null,
        mediaType: showMediaAndTimer ? mediaType : null,
        mostrarTitulo: showTitle,
        mostrarDescripcion: showDescription,
        redirectToExternalPage,
        externalPageUrl: redirectToExternalPage ? externalPageUrl : null,
        inactiveDays, 
        inactiveHours,
        resetHour  // Guardar el horario de reseteo
      });
      

      await setDoc(doc(db, `sorteos/${newSorteoId}/boletos`, 'apartados'), { numeros: [] });
      await setDoc(doc(db, `sorteos/${newSorteoId}/boletos`, 'comprados'), { numeros: [] });

      const cantidadInicial = Math.ceil(parsedCantidad / 2);
      await generarNumerosSorteo(newSorteoId, cantidadInicial, parsedCantidad, isFree ? 1 : (limitNumbers ? maxNumbersPerPerson : null), enablePackages ? packageSizes : []);

      await updateDoc(counterDocRef, {
        current: increment(1)
      });

      onCreate();
      resetForm();
      onClose();

    } catch (error) {
      console.error("Error al crear el sorteo: ", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setNombre('');
    setDescripcion('');
    setCantidad(10000);
    setCosto(0);
    setLimitNumbers(false);
    setMaxNumbersPerPerson(0);
    setEnablePackages(false);
    setPackageSizes([]);
    setPackageDiscounts([]);
    setIsFree(false);
    setDateRange([{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }]);
    setMediaType('image');
    setMediaFile(null);
    setUploadProgress(0);
    setIsUploadComplete(false);
    setIsSubmitting(false);
    setVideoPoster(null);
    setPosterUploadProgress(0);
    setIsPosterUploadComplete(false);
    setShowTitle(true);
    setShowDescription(true);
    setShowMediaAndTimer(true);
    setRedirectToExternalPage(false);
    setExternalPageUrl('');
    setInactiveDays([]); // Resetear días inactivos
    setInactiveHours({ start: '00:00', end: '23:59' }); // Resetear horas inactivas
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePackageSizeChange = (index, value) => {
    const newPackages = [...packageSizes];
    newPackages[index] = Number(value);
    setPackageSizes(newPackages);
  };

  const handlePackageDiscountChange = (index, value) => {
    const newDiscounts = [...packageDiscounts];
    newDiscounts[index] = Number(value);
    setPackageDiscounts(newDiscounts);
  };

  const addPackage = () => {
    setPackageSizes([...packageSizes, 0]);
    setPackageDiscounts([...packageDiscounts, 0]);
  };

  const removePackage = (index) => {
    const newPackages = packageSizes.filter((_, i) => i !== index);
    const newDiscounts = packageDiscounts.filter((_, i) => i !== index);
    setPackageSizes(newPackages);
    setPackageDiscounts(newDiscounts);
  };

  const handleMediaFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploadComplete(false); 
      setUploadProgress(0); 
      setMediaFile(file);
      uploadFileToStorage(file, `temp/${file.name}`, setUploadProgress, setIsUploadComplete);
    }
  };

  const handlePosterFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsPosterUploadComplete(false); 
      setPosterUploadProgress(0); 
      setVideoPoster(file);
      uploadFileToStorage(file, `temp/${file.name}`, setPosterUploadProgress, setIsPosterUploadComplete);
    }
  };

  // Funciones para gestionar la inactividad
  const handleInactiveDayChange = (day) => {
    setInactiveDays(prevDays => {
      if (prevDays.includes(day)) {
        return prevDays.filter(d => d !== day);
      } else {
        return [...prevDays, day];
      }
    });
  };

  const renderInactividadStep = () => (
    <div>
      <h3 className="text-lg font-medium mb-2">Seleccionar Días y Horarios Inactivos</h3>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Seleccionar Días Inactivos:</label>
        <div className="grid grid-cols-7 gap-2">
          {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day, index) => (
            <button
              key={index}
              onClick={() => handleInactiveDayChange(day)}
              className={`p-2 border rounded ${inactiveDays.includes(day) ? 'bg-red-500 text-white' : 'bg-gray-100'}`}
            >
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </button>
          ))}
        </div>
      </div>
  
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">Seleccionar Horario Inactivo:</label>
        <div className="flex space-x-2">
          <input
            type="time"
            value={inactiveHours.start}
            onChange={(e) => setInactiveHours({ ...inactiveHours, start: e.target.value })}
            className="p-2 border rounded"
          />
          <span>-</span>
          <input
            type="time"
            value={inactiveHours.end}
            onChange={(e) => setInactiveHours({ ...inactiveHours, end: e.target.value })}
            className="p-2 border rounded"
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}

      </div>
  
      <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700 mb-1">Seleccionar Hora de Reseteo:</label>
  <input
    type="text"
    value={resetHour}
    onChange={(e) => handleResetHourChange(e.target.value)}  // Validación en el onChange
    className="p-2 border rounded w-full"
    placeholder="HH:MM:SS"
  />
</div>

    </div>
  );
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-auto p-6 relative" style={{ minHeight: '500px', maxHeight: '600px' }}>
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-xl font-bold">
          &times;
        </button>
        <h2 className="text-2xl font-semibold mb-4">Crear Sorteo - Paso {currentStep}</h2>

        {currentStep === 1 && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Nombre del Sorteo</label>
            <input
              type="text"
              placeholder="Nombre del Sorteo"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              className="p-2 border border-gray-300 rounded mb-4 w-full"
            />
            <label className="block text-sm font-medium text-gray-700 mb-1">Descripción</label>
            <input
              type="text"
              placeholder="Descripción"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              className="p-2 border border-gray-300 rounded mb-4 w-full"
            />
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={isFree}
                onChange={(e) => setIsFree(e.target.checked)}
                className="mr-2"
              />
              <label className="text-sm font-medium text-gray-700">Sorteo Gratuito</label>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={showTitle}
                onChange={(e) => setShowTitle(e.target.checked)}
                className="mr-2"
              />
              <label className="text-sm font-medium text-gray-700">Mostrar Título</label>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={showDescription}
                onChange={(e) => setShowDescription(e.target.checked)}
                className="mr-2"
              />
              <label className="text-sm font-medium text-gray-700">Mostrar Descripción</label>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={showMediaAndTimer}
                onChange={(e) => setShowMediaAndTimer(e.target.checked)}
                className="mr-2"
              />
              <label className="text-sm font-medium text-gray-700">Mostrar Imagen de Portada y Temporizador</label>
            </div>

            <div className="flex justify-end">
              <button
                onClick={nextStep}
                className="p-2 bg-blue-500 text-white rounded"
                disabled={!nombre || !descripcion}
              >
                Siguiente
              </button>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            {!isFree && (
              <>
                <label className="block text-sm font-medium text-gray-700 mb-1">Costo del Boleto</label>
                <input
                  type="number"
                  placeholder="Costo del Boleto"
                  value={costo}
                  onChange={(e) => setCosto(Number(e.target.value))}
                  className="p-2 border border-gray-300 rounded mb-4 w-full"
                />
              </>
            )}
            <label className="block text-sm font-medium text-gray-700 mb-1">Cantidad de Números</label>
            <input
              type="number"
              placeholder="Cantidad de Números"
              value={cantidad}
              onChange={(e) => setCantidad(Number(e.target.value))}
              className="p-2 border border-gray-300 rounded mb-4 w-full"
            />

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={limitNumbers}
                onChange={(e) => setLimitNumbers(e.target.checked)}
                className="mr-2"
                disabled={isFree}
              />
              <label className="text-sm font-medium text-gray-700">Limitar Números por Persona</label>
            </div>

            {limitNumbers && !isFree && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Máximo Números por Persona</label>
                <input
                  type="number"
                  placeholder="Número máximo"
                  value={maxNumbersPerPerson}
                  onChange={(e) => setMaxNumbersPerPerson(Number(e.target.value))}
                  className="p-2 border border-gray-300 rounded mb-4 w-full"
                  min="1"
                />
              </div>
            )}

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={enablePackages}
                onChange={(e) => setEnablePackages(e.target.checked)}
                className="mr-2"
              />
              <label className="text-sm font-medium text-gray-700">Crear Paquetes de Boletos</label>
            </div>

            {enablePackages && (
              <div>
                {packageSizes.map((size, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <label className="text-sm font-medium text-gray-700 mr-2">Paquete de</label>
                    <input
                      type="number"
                      placeholder="Tamaño del paquete"
                      value={size}
                      onChange={(e) => handlePackageSizeChange(index, e.target.value)}
                      className="p-2 border border-gray-300 rounded w-full mr-2"
                      min="1"
                    />
                    <label className="text-sm font-medium text-gray-700 mr-2">Descuento (%)</label>
                    <input
                      type="number"
                      placeholder="Descuento"
                      value={packageDiscounts[index]}
                      onChange={(e) => handlePackageDiscountChange(index, e.target.value)}
                      className="p-2 border border-gray-300 rounded w-full"
                      min="0"
                      max="100"
                    />
                    <button
                      onClick={() => removePackage(index)}
                      className="ml-2 text-red-500"
                    >
                      🗑️
                    </button>
                  </div>
                ))}
                <button
                  onClick={addPackage}
                  className="p-2 bg-green-500 text-white rounded mt-2"
                >
                  Añadir Paquete
                </button>
              </div>
            )}

            <div className="flex justify-between mt-4">
              <button
                onClick={prevStep}
                className="p-2 bg-gray-500 text-white rounded"
              >
                Anterior
              </button>
              <button
                onClick={nextStep}
                className="p-2 bg-blue-500 text-white rounded"
                disabled={!isFree && (costo <= 0 || cantidad <= 0)}
              >
                Siguiente
              </button>
            </div>
          </div>
        )}

        {currentStep === 3 && renderInactividadStep()}

        {(currentStep === 3 && !showMediaAndTimer) || currentStep === 4 ? (
          <div>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={redirectToExternalPage}
                onChange={(e) => setRedirectToExternalPage(e.target.checked)}
                className="mr-2"
              />
              <label className="text-sm font-medium text-gray-700">
                Redirigir el envío del formulario a una página externa
              </label>
            </div>

            {redirectToExternalPage && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  URL de la página externa
                </label>
                <input
                  type="text"
                  placeholder="https://ejemplo.com/pagina-receptora"
                  value={externalPageUrl}
                  onChange={(e) => setExternalPageUrl(e.target.value)}
                  className="p-2 border border-gray-300 rounded mb-4 w-full"
                />
              </div>
            )}

            {!redirectToExternalPage && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Redirigir al componente de "Gracias" de la aplicación
                </label>
              </div>
            )}

            <div className="flex justify-between mt-4">
              <button
                onClick={prevStep}
                className="p-2 bg-gray-500 text-white rounded"
              >
                Anterior
              </button>
              <button
                onClick={handleCrearSorteo}
                className="p-2 bg-green-500 text-white rounded"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creando Sorteo..." : "Crear Sorteo"}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CrearSorteoModal;
